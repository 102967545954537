import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'
import Fade from 'react-reveal/Fade'

import SEO from '../components/seo'
import Link from '../utils/link'
import RelatedPosts from '../components/related-posts'

class teamTemplate extends Component {

  render() {
    let page = this.props.data.wordpressWpMember
    let metaDescription = page.yoast_meta.find(x => x.name === 'description') ? page.yoast_meta.find(x => x.name === 'description').content : '';

    return (
      <>
        <SEO title={he.decode(page.yoast_title)} bodyClass={'team'} description={metaDescription} />
        <section className='team'>
          <Fade>
            <div className='team__inner'>
              <div className="team__inner__picture-info">
                <picture>
                  <img src={page.acf.feature_image.localFile.childImageSharp.original.src} alt={page.acf.feature_image.localFile.childImageSharp.original.alt_text} />
                </picture>
                <div className="team__inner__picture-info__info">
                  <div className="details">
                    <p className="name">{page.acf.name}</p>
                    <p className="feint-role">{page.acf.job_title}</p>
                    {page.acf.email && <p><Link to={`mailto:${page.acf.email}`}>Email</Link></p>}
                    {page.acf.linkedin && <p><Link to={page.acf.linkedin}>LinkedIn</Link></p>}
                  </div>
                  <div className="qualifications">
                    {page.acf.qualifications?.map((el, i) =>
                      <div className="qualifications__inner" key={i}>
                        <p>{el.what}</p>
                        <p className="feint"> {el.where}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="team__inner__info">
                <p dangerouslySetInnerHTML={{ __html: page.acf.bio }} />
              </div>
            </div>
          </Fade>
        </section>
        {page.acf.experience && <RelatedPosts title='Relevant Experience' posts={page.acf.experience} />}
      </>
    )
  }
}

export default teamTemplate

export const team = graphql`
  query ($id: String!) {
    wordpressWpMember(id: {eq: $id}) {
      yoast_title
      yoast_meta {
       name
       content
       property
      }
      title
      acf {
        feature_image {
          ...original
				}
				name
				job_title
				email
				linkedin
				qualifications {
					what
					where
				}
				bio
				experience {
					title: post_title
          slug: post_name
          type: post_type
				}
      }
    }
  }
`
